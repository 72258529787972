<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form
      ref="form"
      @submit.prevent="onSubmit"
    >
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :rules="[validators.required,validators.lengthValidator(currentPassword, 8)]"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :label="$t('Mot de passe actuel')"
                :error-messages="errorMessages.currentPassword"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :label="$t('Nouveau mot de passe')"
                :error-messages="errorMessages.newPassword"
                :rules="[validators.required,validators.lengthValidator(newPassword, 8)]"
                outlined
                dense
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :rules="[validators.required,validators.lengthValidator(cPassword, 8), validators.confirmedValidator(cPassword, newPassword)]"
                :label="$t('Confirmer le mot de passe')"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :error-messages="errorMessages.cPassword"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <v-snackbar
        v-model="isSnackbarBottomVisible"
        :color="snackBarColor"
        :light="$vuetify.theme.dark"
        timeout="5000"
      >
        {{ snackBarMessage }}
      </v-snackbar>
      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <!--        <v-card-title class="flex-nowrap">
          <v-icon class="text&#45;&#45;primary me-3">
            {{ icons.mdiKeyOutline }}
          </v-icon>
          <span class="text-break">Two-factor authentication</span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-center mx-auto">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary&#45;&#45;text mb-4"
            rounded
          >
            <v-icon
              size="25"
              color="primary"
            >
              {{ icons.mdiLockOpenOutline }}
            </v-icon>
          </v-avatar>
          <p class="text-base text&#45;&#45;primary font-weight-semibold">
            Two factor authentication is not enabled yet.
          </p>
          <p class="text-sm text&#45;&#45;primary">
            Two-factor authentication adds an additional layer of
            security to your account by requiring more than just a
            password to log in. Learn more.
          </p>
        </v-card-text>-->

        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            type="submit"
            :loading="loading"
          >
            {{ $t("Sauvegarder") }}
          </v-btn>
          <!--          <v-btn
            color="secondary"
            outlined
            class="mt-3"
          >
            {{ $t("Annuler") }}
          </v-btn>-->
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'
import {
  required, emailValidator, lengthValidator, confirmedValidator,
} from '@core/utils/validation'
import store from '@/store'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const form = ref(null)
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref(null)
    const newPassword = ref(null)
    const cPassword = ref(null)
    const errorMessages = ref({ })
    const valid = ref(false)
    const loading = ref(false)
    const isLoadingUsers = ref(false)
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const snackBarColor = ref('success')

    const onSubmit = () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      /* if (cPassword.value !== newPassword.value) {
        errorMessages.value = { cPassword: vm.$t('Le mot de passe ne correspond pas') }
        form.value.validate()

        return
      } */
      loading.value = true
      store.dispatch('app-user/changePassword', { currentPassword: currentPassword.value, newPassword: newPassword.value })
        .then(response => {
          loading.value = false
          if (response.data.error || !response.data.success || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...response.data.errors }
            } else {
              isSnackbarBottomVisible.value = true
              snackBarMessage.value = response.data.message
              snackBarColor.value = vm.$vuetify.theme.currentTheme.error

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          form.value.reset()
          isSnackbarBottomVisible.value = true
          snackBarColor.value = vm.$vuetify.theme.currentTheme.success
          snackBarMessage.value = vm.$t('Le mot de passe a été réinitialisé.')
        }).catch(error => {
          loading.value = false
          console.log('error', error)

          /* if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            } */
        })
    }

    return {
      isSnackbarBottomVisible,
      snackBarMessage,
      snackBarColor,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      loading,
      isLoadingUsers,
      valid,
      form,
      errorMessages,
      validators: {
        required, emailValidator, lengthValidator, confirmedValidator,
      },
      onSubmit,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
