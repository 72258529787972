<template>
  <div id="author-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Activités") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('Rechercher')"
          outlined
          hide-details
          dense
          class="author-search me-3 mb-4"
        >
        </v-text-field>
      </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        class="fixed-table"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-footer
        :show-select="false"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        @page-count="pageCount = $event"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            icon
            small
          >
            <v-icon
              size="20"
              @click="openDialog(item)"
            >
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="error"
          >
            <v-icon
              size="20"
              color="error"
              @click="deleteItem(item)"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDotsVertical,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// sidebar
import { formatDate, formatDateToMonthShort } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import router from '@/router'
import RowsPerPage from '@/components/RowsPerPage'

export default {
  components: {
    RowsPerPage,
  },
  setup() {
    const activities = ref('')
    const items = ref([])
    const authorId = ref(null)
    const loadingActivities = ref(true)
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const isFormActive = ref(false)
    const searchQuery = ref('')
    const payFilter = ref(null)
    const supervisorFilter = ref(null)
    const statusFilter = ref(null)
    const totalItems = ref(0)
    const loading = ref(false)
    const options = ref({
      itemsPerPage: 10,
      rowsPerPage: 10,
      page: 1,
      sortDesc: [true],
    })
    const page = ref(1)
    const pageCount = ref(0)
    const authorTotalLocal = ref([])
    const selectedRows = ref([])
    const tableColumns = [
      { text: 'Action', value: 'action' },
      { text: 'Type', value: 'actionable_type' },
      { text: 'Description', value: 'description' },
      { text: 'Date', value: 'created_at' },
    ]
    if (router.currentRoute.params.id) {
      authorId.value = router.currentRoute.params.id
    } else {
      authorId.value = store.state.app.author.id
    }
    const fetchData = () => {
      axios.get(`/authors/activities/${authorId.value}`, {
        params: {
          term: searchQuery.value,
          orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
          // eslint-disable-next-line no-nested-ternary
          direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
          page: options.value.page,
          per_page: options.value.itemsPerPage,
        },
      }).then(response => {
        items.value = response.data.data
        totalItems.value = response.data.total
      }).then(() => {
        loading.value = false
      })
    }
    watch([searchQuery, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchData()
    })
    fetchData()

    return {
      activities,
      items,
      tableColumns,
      searchQuery,
      payFilter,
      supervisorFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      authorTotalLocal,
      selectedRows,
      isFormActive,
      page,
      pageCount,
      snackBarMessage,
      isSnackbarBottomVisible,
      loadingActivities,
      icons: {
        mdiDotsVertical,
      },
      formatDate,
      formatDateToMonthShort,
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
