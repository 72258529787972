<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Ajouter un publication") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row class="pt-5">
<!--          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.isbn"
              outlined
              dense
              :error-messages="errorMessages.isbn"
              :label="$t('ISBN')"
              :placeholder="$t('ISBN')"
              hide-details="auto"
            ></v-text-field>
          </v-col>-->
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.nb_da_paper"
              outlined
              dense
              :error-messages="errorMessages.nb_da_paper"
              :label="$t('DA papier')"
              :placeholder="$t('DA papier')"
              hide-details="auto"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.nb_da_num"
              outlined
              dense
              :error-messages="errorMessages.nb_da_num"
              :label="$t('DA num')"
              :placeholder="$t('DA num')"
              hide-details="auto"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.nb_copies"
              outlined
              dense
              :error-messages="errorMessages.nb_copies"
              :label="$t('Nb ex')"
              :placeholder="$t('Nb ex')"
              hide-details="auto"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.format_id"
              :label="$t('Format')"
              :error-messages="errorMessages.format_id"
              :items="$store.state['app'].formats.filter((ele)=>ele.format_type === 'Publication' )"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.shipping_costs"
              type="number"
              outlined
              :error-messages="errorMessages.shipping_costs"
              :label="$t('Frais de port')"
              :placeholder="$t('Frais de port')"
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.sum_pages"
              type="number"
              outlined
              :error-messages="errorMessages.sum_pages"
              :label="$t('Nombre de pages')"
              :placeholder="$t('Nombre de pages')"

              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.price"
              type="number"
              outlined
              :rules="[ item.custom_price ?validators.required:true,]"
              :error-messages="errorMessages.price"
              :label="$t('Prix public')"
              :readonly="!item.custom_price"
              :append-icon="item.custom_price ? icons.mdiPencilOffOutline : icons.mdiPencilOutline"
              dense
              hide-details="auto"
              @click:append="item.custom_price = !item.custom_price"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.genre_id"
              :label="$t('Genre')"
              :error-messages="errorMessages.genre_id"
              :items="$store.state['app'].genres"
              item-text="label"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">

          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'

export default {
  components: { AuthorForm, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const {} = authorsController()
    const errorMessages = ref({ })
    const valid = ref(false)
    const modalReceipt = ref(false)
    const modalResponse = ref(false)
    const modalProposition = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingPublications = ref(false)
    const authors = ref([])
    const fees = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })


    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const formatDate = (date, f) => format(date, f)
    const newAuthor = () => {
      item.value.author = {
        full_name: search.value, email: null, phone: null, addresses: [],
      }
      isFormAuthorActive.value = true
    }
    const searchAuthors = term => {
      store.dispatch('app/searchAuthors', { term, rowsPerPage: 100 }).then(response => {
        isLoadingAuthors.value = false
        authors.value = response.data.data
      })
    }
    axios.get('/fees').then(response => {
      fees.value = response.data.data
    })
    const updateAuthors = author => {
      authors.value.push(author)
      item.value.author_id = author.id
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('app-publication/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    searchAuthors('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingAuthors.value) return ''
      isLoadingAuthors.value = true
      searchAuthors(val)

      return ''
    })
    const nbPages = computed({
      get: () => item.value.sum_pages,

      /* set: value => emit('update:object', value), */
    })
    const nbWords = computed({
      get: () => item.value.sum_words,
    })
    const calculatePrice = () => {
      const fee = fees.value.find(i => i.page_min <= nbPages.value && i.page_max >= nbPages.value)

      if (fee && (nbPages.value)) {
        const price = parseFloat(fee.frais)
        const sub = parseInt(nbPages.value - fee.subtraction, 10)
        item.value.price = parseFloat((price + parseFloat((sub * fee.percentage).toFixed(2))).toFixed(2))
      } else {
        item.value.price = store.state.app.settings.default_pages_price || 27
      }
    }
    const calculatePages = () => {
      // item.value.sum_pages = null
      if (!item.value.custom_pages && item.value.sum_words >= 0) {
        const words = store.state.app.settings.number_words_per_page || 260
        item.value.sum_pages = Math.ceil(item.value.sum_words / words)
      } else {
        console.log('test')
        item.value.sum_pages = null
      }
    }

    watch(nbPages, val => {
      calculatePrice()

      return ''
    })
    watch(nbWords, val => {
      calculatePages()
      return ''
    })

    store
      .dispatch('app/fetchSources', { per_page: 500 })
    return {
      resetItem,
      form,
      isFormAuthorActive,
      isLoadingPublications,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      search,
      authors,
      isLoadingAuthors,
      onSubmit,
      updateAuthors,
      modalReceipt,
      modalProposition,
      modalResponse,
      formatDate,
      newAuthor,
      item,
      valid,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
